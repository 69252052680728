import { AppBar } from '@local/web-design-system/dist/components/AppBar';
import type { LinkListIcon } from '@local/web-design-system/dist/components/LinkListItem';
import { NavDrawer } from '@local/web-design-system/dist/components/NavDrawer';
import classnames from 'classnames';
import { useState } from 'react';
import { Outlet, useLocation } from 'react-router';

import logo from '../../assets/logo.svg';
import { LogoTitle } from '../../assets/logoTitle';
import { useStyles } from './ContentScaffloding.styles';

function NavContent({
    isDrawerOpen,
    toggleDrawer,
}: {
    isDrawerOpen: boolean;
    toggleDrawer: () => void;
}) {
    const { classes } = useStyles();
    const location = useLocation();
    const navItems = [
        {
            key: 'Instruments',
            text: 'Instruments',
            location: 'instruments',
            icon: 'home' as LinkListIcon,
        },
        // {
        //     key: 'QC',
        //     text: 'QC',
        //     location: '/dataplot',
        //     icon: 'integrations' as LinkListIcon,
        // },
        {
            key: 'DataProfile',
            text: 'Data Profile',
            location: 'dataprofile',
            icon: 'integrations' as LinkListIcon,
        },
        {
            key: 'ModelSection',
            text: 'Model Section',
            location: 'modelsection',
            icon: 'integrations' as LinkListIcon,
        },
    ];

    return (
        <NavDrawer
            navItems={navItems}
            isDrawerOpen={isDrawerOpen}
            currentRoute={location.pathname}
            exactMatch={false}
            logoOnly={<img src={logo} className={classes.appLogo} alt="example" />}
            logoWithTitle={<LogoTitle />}
            toggleDrawer={toggleDrawer}
        />
    );
}

export const ContentScaffolding = () => {
    const { classes } = useStyles();
    const [isDrawerOpen, setIsDrawerOpen] = useState(true);
    const toggleDrawer = () => {
        setIsDrawerOpen(!isDrawerOpen);
    };

    return (
        <div>
            <NavContent isDrawerOpen={isDrawerOpen} toggleDrawer={toggleDrawer} />
            <AppBar
                isDrawerOpen={isDrawerOpen}
                toggleDrawer={toggleDrawer}
                classes={classes}
                variant="dark"
                profileComponent={<div />}
            />
            <div
                className={classnames(classes.appContent, {
                    [classes.appBarShift]: isDrawerOpen,
                })}
                automation-id="content-div"
            >
                <Outlet />
            </div>
        </div>
    );
};
